import React, { useState } from 'react';
import GrowthChart from './GrowthChart';
import CompareForm from './etfCompare';
import ETFComparisonTable from './ETFPerformance';
import CircularProgress from '@mui/material/CircularProgress';
import {Box,Typography} from '@mui/material';
import HoldingsTable from './holdingsCompare';


const MainComponent = () => {
    const [portfolio, setPortfolio] = useState(null);
    const [performance, setPerformance] = useState(null);
    const [isLoading, setIsLoading] = useState(false);  // Add isLoading state
    const [holdings, setHoldings] = useState(null);

  
    const handleFormSubmit = async (portfolioData, perfData,holdingsData) => {
      setIsLoading(true); // Set loading to true when form is submitted
      setPortfolio(portfolioData);
      setPerformance(perfData);
      setIsLoading(false);
      setHoldings(holdingsData); // Set loading to false when data is fetched
    };
  
    return (
      <div>
        <CompareForm onFormSubmit={handleFormSubmit} setIsLoading={setIsLoading} />


        {isLoading && (
         <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
            <Typography>Loading...</Typography>
         </Box>
        )}
        

        {portfolio && performance && !isLoading && ( // make sure to check !isLoading
            <>
            <ETFComparisonTable data={performance} />
            {holdings && holdings.data && holdings.data.length > 0 && (
              <HoldingsTable data={holdings.data} />
            )}
            <GrowthChart data={portfolio} />
          </>
        )}

      </div>
    );
};
  
export default MainComponent;
