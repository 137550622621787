import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Box,Typography, TableContainer,Paper } from '@mui/material';
import { tokens } from '../../theme';
import { useTheme } from '@mui/material';




const ETFComparisonTable = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  if (!data) {
    return null; // Or return a loading spinner
  }

  
  return data.map((tableData, idx) => {
    const tickers = Object.keys(tableData).filter(key => key !== 'index' && key !== 'Description');
    const metrics = Object.keys(tableData['index']);
    const descriptions = tableData['Description'];
    

    return (
      <Box key={idx} mb={4} mt={idx === 0 ? 0 : 4} px={3}> 
  {idx === 0 && (
    <Typography variant="h6" className="heroTitle" align="center">
      Performance Summary
    </Typography>
  )}
  {idx === 1 && (
    <Typography variant="h6" className="heroTitle" align="center">
      Trailing Returns (%)
    </Typography>
  )}
  <TableContainer component={Paper} sx={{
    maxHeight: '60vh',
    bgcolor:  'white',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  }}>
    <Table aria-label="simple table" sx={{
      '& th, & td': {
        border: '1px solid gray',
      },
      '& thead th': {
        backgroundColor: colors.primary[600],
        position: 'sticky',
        top: 0,
      },
      '& tbody th': {
        position: 'sticky',
        left: 0,
        backgroundColor: 'red',
      },
    }}>
      <TableHead>
        <TableRow>
          <TableCell sx={{ color: 'white' }}>Description</TableCell>
          {tickers.map((ticker) => (
            <TableCell key={ticker} sx={{ color: 'white' }}>{ticker}</TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
      {metrics.map((metric) => (
      <TableRow key={metric} >
        <TableCell sx={{ width: '300px' }}>{descriptions[metric]}</TableCell>
        {tickers.map((ticker) => {
          let cellValue = tableData[ticker][metric];
          if (typeof cellValue === 'string' && cellValue.includes('T')) {
            let date = new Date(cellValue);
            cellValue = date.getFullYear() + '-' + String(date.getMonth()+1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
          } else if (typeof cellValue === 'number') {
            cellValue = Number(cellValue).toFixed(3);
          }
          return <TableCell sx={{ width: '300px', alignItems: 'center' }} key={`${ticker}-${metric}`}>{cellValue}</TableCell>
        })}
      </TableRow>
    ))}
      </TableBody>
    </Table>
  </TableContainer>
</Box>

    );
  });
};

export default ETFComparisonTable;
