import { AppBar, Toolbar,useMediaQuery } from '@mui/material';
import React from 'react';
import { Box, useTheme,Grid } from '@mui/material';
import { tokens } from '../theme';
import logo from "../images/logo.png";




function NavBar() {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down('sm')); // Change the breakpoint as needed
  const colors = tokens(theme.palette.mode);
//   const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
  //const colorMode = useContext(ColorModeContext);
  //const isDark = colorMode.isDark;
    


//     const SignUpComponent = () => {
//         return (
//             <Button variant="contained" onClick={(e) => {
//                 e.preventDefault();
//                 navigate('/signup');
//             }} mt={2} sx={{
//                 backgroundColor: colors.greenAccent[700],
//                 color: colors.grey[100],
//                 fontSize: "14px",
//                 fontWeight: "bold",
//                 padding: "10px 20px",
//                 marginLeft: '16px',
//             }}>Sign Up</Button>

            
//         );
//     };

//     const LoginComponent = () => {
//         return (
//             <Button variant="outlined" onClick={(e) => {
//                 e.preventDefault();
//                 navigate('/login');
//             }} mt={2} sx={{
//                 backgroundColor: colors.greenAccent[700],
//                 color: colors.grey[100],
//                 fontSize: "14px",
//                 fontWeight: "bold",
//                 padding: "10px 20px",
//                 marginLeft: '16px',
//             }}>Login</Button>
            
//         );
//     };

//     const handleLogout = () => {
//       // Dispatch the logout action to update the Redux state
//       dispatch(logout());
//     };

//     const AuthButtons = () => {
//       if (isAuthenticated) {
//           return (
//               <Button variant="outlined" onClick={handleLogout} mt={2} sx={{
//                 backgroundColor: colors.greenAccent[700],
//                 color: colors.grey[100],
//                 fontSize: "14px",
//                 fontWeight: "bold",
//                 padding: "10px 20px",
//                 marginLeft: '16px',
//             }}>Logout</Button>
//           );
//       } else {
//           return (
//               <>
//                   <SignUpComponent />
//                   <LoginComponent />
//               </>
//           );
//       }
//   };

  

    return (
      <header>
      <AppBar position="static" sx={{ backgroundColor: colors.blueAccent[100] }}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item xs={isMatch ? "auto" : 4}>
              <img
                src={logo}
                alt="linkedinIcon"
                style={{ width: "250px", height: "75px" ,borderRadius: '8px', mt:8}}
              />
            </Grid>
            {isMatch ? (
              <Grid item xs>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {/* <DrawerComp AuthButtons={AuthButtons} /> */}
                </Box>
              </Grid>
            ) : (
              <Grid item xs={8}>
                <Box
                  component="nav"
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                    {/* <Box component="a" href="/#" sx={{ mx: 1, textDecoration: 'none', color: 'white' }}>
                      Home
                    
                    </Box>
                     */}
              
                  </Box>
                </Grid>
              )}
            </Grid>
          </Toolbar>
        </AppBar>
       
      </header>

    );
};

export default NavBar;
