import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, TablePagination } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { tokens } from '../../theme'; // Assuming you have a tokens function for colors

const HoldingsTable = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (!data) {
    return null; // Or return a loading spinner
  }

  const tickers = Object.keys(data[0]).filter(key => key !== 'name' && key !== 'symbol' && key !== 'Sector');

  // Calculate the sum of weights for each ticker
  const sumWeights = tickers.reduce((acc, ticker) => {
    acc[ticker] = data.reduce((sum, row) => sum + row[ticker], 0) * 100; // Convert to percentage
    return acc;
  }, {});

  return (
    
    <Box mb={4} px={3}>
      <Typography variant="h6" className="heroTitle" align="center">
       ETF Holdings Overlap
      </Typography>
      <TableContainer component={Paper} sx={{
        maxHeight: '60vh',
        bgcolor: 'white',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}>
        <Table aria-label="holdings table" sx={{
          '& th, & td': {
            border: '1px solid gray',
          },
          '& thead th': {
            backgroundColor: colors.primary[600],
            position: 'sticky',
            top: 0,
          },
        }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: 'white' }}>Asset</TableCell>
              {tickers.map((ticker) => (
                <TableCell key={ticker} sx={{ color: 'white' }}>{ticker}</TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell sx={{ color: 'white' }}>Common Holdings Sum(%)</TableCell>
              {tickers.map((ticker) => (
                <TableCell key={ticker} sx={{ color: 'white' }}>{sumWeights[ticker].toFixed(2)}%</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow key={row.name}>
                <TableCell>{row.name}</TableCell>
                {tickers.map((ticker) => {
                  const weight = row[ticker] * 100; // Convert to percentage
                  return <TableCell key={ticker}>{weight.toFixed(2)}%</TableCell>
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
};

export default HoldingsTable;
