import React, { useEffect, useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { Box, Typography } from '@mui/material';
import HeatMap from './HeatMap';
import { useMediaQuery, useTheme } from '@mui/material';

const transformData = (data) => {
  return Object.keys(data).map((label) => {
    const dates = Object.keys(data[label]);
    const values = dates.map((date) => {
      const newDate = new Date(date);
      const year = newDate.getFullYear();
      const month = newDate.getMonth();
      const simplifiedDate = new Date(year, month, 1, 0, 0, 0); // sets the day to the first of the month and time to 00:00:00
      return { 
        x: simplifiedDate, 
        y: data[label][date],
        dateString: newDate.toLocaleDateString('en-CA')
      };
    });

    return {
      id: label,
      data: values,
    };
  });
};



const customTooltip = ({point}) => {
  return (
    <div
      style={{
        background: 'white',
        padding: '9px 12px',
        border: '1px solid #ccc',
      }}
    >
      <strong>{point.serieId}</strong>: {point.data.yFormatted}
    </div>
  );
};

const GrowthChart = ({ data }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [chartKey, setChartKey] = useState(0);  // eslint-disable-next-line no-unused-vars

    useEffect(() => {
        setChartKey(prevKey => prevKey + 1); // change key when isMobile changes
    }, [isMobile]);

    if (!data) {
        return null;
    }

    const chartWidth = isMobile ? '100%' : '1000px';

    const firstChartData = transformData(data.cumulative);
    const secondChartData = transformData(data.drawdown);
    console.log(firstChartData)

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',ml:2 }}>
                <Typography variant="h6" className="heroTitle">
                    Cumulative Growth
                </Typography>
                <Typography variant="h7" className="heroTitle">
                The graph shows the growth of $10,000 invested in each of the securities selected for comparison. Prices adjusted for splits and dividends.
                </Typography>
                <Box sx={{ width: chartWidth, height: '600px', marginBottom: '2em' }}>
                    <ResponsiveLine
                        key={chartKey}
                        data={firstChartData}
                        margin={{ top: 50, right: 60, bottom: 70, left: 60 }}
                        xScale={{ type: 'time',
                        format: 'native',
                        useUTC: false,
                        precision: 'day', }}
                        xFormat="time:%Y-%m-%d"
                        yScale={{
                          type: 'linear',
                          min: 'auto',
                          max: 'auto',
                          stacked: false,
                          reverse: false
                      }}
                        curve="basis"
                        axisBottom={{
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 45,
                          format: "%Y-%m",
                          legendOffset: 36,
                          legendPosition: 'middle'
                      }}
                        tooltip={customTooltip}
                        enablePoints={false}
                        pointSize={10}
                        pointColor={{ theme: 'background' }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: 'serieColor' }}
                        useMesh={true}
                        colors={{ scheme: 'set1' }}
                        legends={[
                          {
                              anchor: 'bottom-right',
                              direction: 'column',
                              justify: false,
                              translateX: 90,
                              translateY: 0,
                              itemWidth: 100,
                              itemHeight: 20,
                              itemsSpacing: 4,
                              symbolSize: 20,
                              symbolShape: 'circle',
                              itemDirection: 'left-to-right',
                              itemTextColor: '#777',
                              effects: [
                                  {
                                      on: 'hover',
                                      style: {
                                          itemBackground: 'rgba(0, 0, 0, .03)',
                                          itemOpacity: 1
                                      }
                                  }
                              ]
                          }
                      ]}
                    />
                </Box>
                <Typography variant="h6" className="heroTitle">
                    Drawdown
                </Typography>
                <Typography variant="h7" className="heroTitle">
                The Drawdowns chart displays losses from any high point along the way for each compared security.
                </Typography>
                <Box sx={{ width: chartWidth, height: '600px' }}>
                    <ResponsiveLine
                        key={chartKey}
                        data={secondChartData}
                        margin={{ top: 50, right: 60, bottom: 70, left: 60 }}
                        xScale={{ type: 'time',
                        format: 'native',
                        useUTC: false,
                        precision: 'day', }}
                        xFormat="time:%Y-%m-%d"
                        yScale={{
                          type: 'linear',
                          min: 'auto',
                          max: 0,
                          stacked: false,
                          reverse: false
                      }}
                        curve="basis"
                        axisBottom={{
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 45,
                          format: "%Y-%m",
                          legendOffset: 36,
                          legendPosition: 'middle'
                      }}
                        colors={{ scheme: 'set1' }}
                        yFormat=" >-.2%"
                        tooltip={customTooltip}
                        enablePoints={false}
                        pointSize={10}
                        pointColor={{ theme: 'background' }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: 'serieColor' }}
                        useMesh={true}
                        legends={[
                          {
                              anchor: 'bottom-right',
                              direction: 'column',
                              justify: false,
                              translateX: 90,
                              translateY: 0,
                              itemWidth: 100,
                              itemHeight: 20,
                              itemsSpacing: 4,
                              symbolSize: 20,
                              symbolShape: 'circle',
                              itemDirection: 'left-to-right',
                              itemTextColor: '#777',
                              effects: [
                                  {
                                      on: 'hover',
                                      style: {
                                          itemBackground: 'rgba(0, 0, 0, .03)',
                                          itemOpacity: 1
                                      }
                                  }
                              ]
                          }
                      ]}
                    />
                </Box>
                
                <Typography variant="h6" className="heroTitle">
                    Monthly Returns Summary
                </Typography>
                <Typography variant="h7" className="heroTitle">
                The Monthly Returns Heatmap shows Month over Month Performace of the Security.
                </Typography>
                <Box sx={{ width: '100%', height: '500px' , mt:5, mb:8}}>
                
                    <HeatMap data={data} />
                </Box>
                {/* <Typography variant="h6" className="heroTitle">
                    ETF Holdings Distribution
                </Typography> */}
                {/* <Typography variant="h7" className="heroTitle">
                The Holdings Distribution shows the Sector-wise Holdings of the Security.
                </Typography>
                <Box sx={{ width: '100%', height: '600px' }}>
                    <FundHoldingsChart data={data} />
                </Box> */}
            </Box>
        </>
    );
};

export default GrowthChart;
