// Footer.js
import { styled, Typography,useTheme,Link } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import fb from '../images/fb.png';
import reddit from "../images/reddit.png";
import instagram from "../images/instagram.png";
import twitter from "../images/twitter.png";
import { tokens } from "../theme";


const Footer = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const CustomContainer = styled(Container)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-around",
    gap: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  }));

  const IconBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  }));

  const FooterLink = styled("span")(({ theme }) => ({
    fontSize: "16px",
    color: "#00000",
    fontWeight: "300",
    cursor: "pointer",
    "&:hover": {
      color: "#000",
    },
  }));

  return (
    <Box sx={{ py: 10, bgcolor: colors.primary[400]}}>
      <CustomContainer>
        <CustomContainer>
          {/* <Box>
            <Typography
              sx={{
                fontSize: "20px",
                color: "#00000",
                fontWeight: "700",
                mb: 2,
              }}
            >
              Products
            </Typography>

            <FooterLink>Listing</FooterLink>
            <br />
            <FooterLink>Properties</FooterLink>
            <br />
            <FooterLink>Agents</FooterLink>
            <br />
            <FooterLink>Blog</FooterLink>
          </Box> */}

          

          <Box>
            <Typography
              sx={{
                fontSize: "20px",
                color: "#00000",
                fontWeight: "700",
                mb: 2,
              }}
            >
              Company
            </Typography>

            <FooterLink>About Us</FooterLink>
            <br />
            <FooterLink>Terms of use</FooterLink>
            <br />
            <FooterLink>Privacy</FooterLink>
            <br />
            <FooterLink>Sitemap</FooterLink>
          </Box>

          <Box>
            <Typography
              sx={{
                fontSize: "20px",
                color: "#00000 ",
                fontWeight: "700",
                mb: 2,
              }}
            >
              Get in touch
            </Typography>

            <Typography
              sx={{
                fontSize: "16px",
                color: "#00000",
                fontWeight: "500",
                mb: 2,
              }}
            >
            etfwizard09@gmail.com
            </Typography>

            <IconBox sx = {{justifyContent: "center"}}>
            <Link href="https://www.facebook.com/profile.php?id=100091624670152" target="_blank" rel="noopener noreferrer">
              <img src={fb} alt="fbIcon" style={{ width: "50px", height: "50px" }} />
              </Link>
              <img
                src={reddit}
                alt="reddutIcon"
                style={{ width: "50px", height: "50px" }}
              />
              <Link href="https://www.instagram.com/propfi.ae/" target="_blank" rel="noopener noreferrer">
              <img
                src={instagram}
                alt="instaIcon"
                style={{ width: "50px", height: "50px" }}
              />
              </Link>
              <Link href="https://twitter.com/Propfi_ae" target="_blank" rel="noopener noreferrer">
              <img
                src={twitter}
                alt="twitterIcon"
                style={{ width: "50px", height: "50px" }}
              />
              </Link>
            </IconBox>
          </Box>
        </CustomContainer>
      </CustomContainer>
      <Box mt={8} align='center'>
        <Typography>© 2023 ETFWizard Inc. All Rights Reserved.</Typography>
      </Box>
    </Box>
    
  );
};

export default Footer;