import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from './components/Navbar';
import MainComponent from './components/ETF/MainComponent';
import Footer from './components/Footer';
import ReactGA from "react-ga4";
//import EtfScreener from './components/Screener/EtfScreener';
//import FundDetails from './components/ETF_page/etfdetails';
//import PortfolioAnalysisForm from './components/Portfolio/portfolioForm';

function App() {
  ReactGA.initialize('G-LKX790QNPJ');
  ReactGA.send("pageview");

  return (
    <div className="App">
      <Router>
        <header className="App-header">
          <NavBar/>
          <Routes>
          
            <Route path="/" element={<MainComponent />} />
            {/* <Route path="/portfolio" element={<PortfolioAnalysisForm />} /> */}
            {/* <Route path="/etf/:ticker" element={<FundDetails />} /> */}
          </Routes>
          <Footer/>
        </header>
      </Router>
    </div>
  );
}

export default App;
