import React, { useState } from 'react';
import { ResponsiveHeatMap } from '@nivo/heatmap';
import { Tabs, Tab, useTheme, useMediaQuery,Box } from '@mui/material';

const HeatMap = ({ data }) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    if (!data || !Array.isArray(data.monthly_returns) || !data.monthly_returns.length) {
        return null;
    }

    const mapData = data.monthly_returns[selectedTab].values;
    const getMinMaxValues = (data) => {
        let min = Infinity;
        let max = -Infinity;
      
        data.forEach((obj) => {
          obj.data.forEach((subObj) => {
            min = Math.min(min, subObj.y);
            max = Math.max(max, subObj.y);
          });
        });
      
        return { min, max };
      };
      
    const { min, max } = getMinMaxValues(mapData);

    return (
        <Box style={{ height: isMobile ? '400px' : '500px', width: '100%', mt:10 }}>
            <Tabs value={selectedTab} onChange={handleTabChange} centered>
                {data.monthly_returns.map((item, idx) => (
                    <Tab label={item.ticker} key={idx} />
                ))}
            </Tabs>
            
            <ResponsiveHeatMap
                data={mapData}
                margin={isMobile ? { top: 40, right: 10, bottom: 1, left: 40 } : { top: 40, right: 85, bottom: 80, left: 85 }}
                valueFormat= {isMobile ?">-0.0%" :">-0.2%"}
                axisTop={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -90,
                    legend: '',
                    legendOffset: 46
                }}
               
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Year',
                    legendPosition: 'middle',
                    legendOffset: -72
                }}
                colors={{
                    type: 'diverging',
                    scheme: 'red_yellow_green',
                    minValue: min,
                    maxValue: max,
                    divergeAt: 0.5
                }}
                emptyColor="#555555"
                legends={[
                    {
                        anchor: 'bottom',
                        translateX: 0,
                        translateY: 30,
                        length: 400,
                        thickness: 8,
                        direction: 'row',
                        tickPosition: 'after',
                        tickSize: 3,
                        tickSpacing: 4,
                        tickOverlap: false,
                        tickFormat: '>-.0%',
                        title: 'Value →',
                        titleAlign: 'start',
                        titleOffset: 4
                    }
                ]}
            />
        </Box>
    );
};

export default HeatMap;
