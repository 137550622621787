import React, { useEffect, useState, useRef } from 'react';
import { Box, TextField, Button, Grid, IconButton, FormControl, Typography,Autocomplete } from '@mui/material';
import { Formik, FieldArray, Form, useField } from 'formik';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import * as Yup from 'yup';
import axios from 'axios';
import { useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import ReactGA from "react-ga4";
import algoliasearch from 'algoliasearch/lite';
import { autocomplete } from '@algolia/autocomplete-js';
import '@algolia/autocomplete-theme-classic';
import aa from 'search-insights';

const AlgoliaAutocompleteField = ({ name }) => {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(name);
  const autocompleteContainerRef = useRef(null);

  useEffect(() => {
    aa('init', {
      appId: '0LPM6E0YXN',
      apiKey: 'df86b33e376e9c773d9992175116366f',
    });
  }, []);

  useEffect(() => {
    if (!autocompleteContainerRef.current) {
      return undefined;
    }
  
    const searchClient = algoliasearch(
      '0LPM6E0YXN',
      'df86b33e376e9c773d9992175116366f',
    );
  
    const search = autocomplete({
      container: autocompleteContainerRef.current,
      placeholder: 'Enter ETF Symbol or Name',
      openOnFocus: true,
      getSources() {
        return [
          {
            sourceId: 'products',
            getItems({ query }) {
              return searchClient
                .initIndex('ETFs')
                .search(query, {
                  hitsPerPage: 5,
                })
                .then(({ hits }) => hits);
            },
            templates: {
              item({ item }) {
                return item.name;
              },
            },
            onSelect({ item, setQuery }) {
              setQuery(item.name);
              helpers.setValue(item.symbol);  // Set the field value here
            },
          },
          {
            sourceId: 'i_etf',
            getItems({ query }) {
              return searchClient
                .initIndex('Irish ETFs') // Change to your actual index name for Irish ETFs
                .search(query, {
                  hitsPerPage: 5,
                })
                .then(({ hits }) => hits);
            },
            templates: {
              item({ item }) {
                return item.name; // Adjust based on how you want to display items from this index
              },
            },
            onSelect({ item, setQuery }) {
              setQuery(item.name);
              helpers.setValue(item.symbol); // Set the isin as the value to be sent to the API
            },
          },
        ];
      },
    });
  
    return () => {
      search.destroy();
    };
  }, [helpers]);


  return (
    <div ref={autocompleteContainerRef} />
  );
};

const CompareForm = ({ onFormSubmit, setIsLoading }) => {
  const [initialValues,] = useState({ tickers: ['', ''], benchmark: '^GSPC' });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
 

  const benchmarkOptions = [
    {symbol: '^GSPC', name: 'S&P 500'},
    {symbol: '^DJI', name: 'Dow Jones Industrial Average'},
    {symbol: '^IXIC', name: 'NASDAQ Composite'},
    {symbol: '^NYA', name: 'NYSE COMPOSITE (DJ)'},
    {symbol: '^XAX', name: 'NYSE AMEX COMPOSITE INDEX'},
    {symbol: '^BUK100P', name: 'Cboe UK 100'},
    {symbol: '^RUT', name: 'Russell 2000'},
    {symbol: '^FTSE', name: 'FTSE 100'},
    {symbol: 'GC=F', name: 'GOLD'},
  ];

  useEffect(() => {
    ReactGA.initialize('G-LKX790QNPJ');
    ReactGA.send("pageview");
  }, []);



  const validationSchema = Yup.object({
    tickers: Yup.array().of(
      Yup.string().required('Ticker required')
    ),
    benchmark: Yup.string().required('Benchmark is required'),
  });

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const tickersWithBenchmark = [...values.tickers, values.benchmark];
    const tickersForHoldings = tickersWithBenchmark.slice(0, -1).join(','); // Exclude the last item
    const tickersForOtherCalls = tickersWithBenchmark.join(',');
    try {
      const portfolioResponse = await axios.get(`https://api.etfwizard.xyz/etf_compare/etf_compare/?tickers=${tickersForOtherCalls}`);
      const performanceResponse = await axios.get(`https://api.etfwizard.xyz/etf_compare/perf_metrics?tickers=${tickersForOtherCalls}`);
      console.log("Before holdings API call");
      const holdingsResponse = await axios.get(`https://api.etfwizard.xyz/etf_compare/holdings_overlap/?tickers=${tickersForHoldings}`);
      console.log("After holdings API call", holdingsResponse.data);

      onFormSubmit(portfolioResponse.data, performanceResponse.data, holdingsResponse.data);
    } catch (error) {
      console.error(error);
    }
};


  return (
    <Box sx={{ width: '80%', margin: 'auto', mt:10, mb:10 }}>
      <Box 
        className="heroContent" 
        mt={10}
        mb={5}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h3" className="heroSubheading">
          ETF Comparison
        </Typography>
        <Typography variant="h6" className="heroTitle">
          ETF comparison is a tool that allows you to compare key performance indicators of ETFs, such as profitability and riskiness.
        </Typography>
      </Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, getFieldMeta }) => (
          <Form>
            <Grid container spacing={3} justifyContent="right" alignItems="right" flexDirection="row">
              <FieldArray name="tickers">
                {arrayHelpers => (
                  <>
                    {values.tickers.map((ticker, index) => (
                      <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                        <FormControl fullWidth>
                          <AlgoliaAutocompleteField name={`tickers[${index}]`} />
                          {values.tickers.length > 2 && (
                            <IconButton aria-label="remove" onClick={() => arrayHelpers.remove(index)}>
                              <RemoveCircleOutlineIcon />
                            </IconButton>
                          )}
                        </FormControl>
                      </Grid>
                    ))}
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <IconButton aria-label="add" onClick={() => arrayHelpers.push('')}>
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </Grid>
                  </>
                )}
              </FieldArray>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormControl fullWidth>
                  <Autocomplete
                    value={benchmarkOptions.find((opt) => opt.symbol === values.benchmark)}
                    options={benchmarkOptions}
                    getOptionLabel={(option) => option ? option.name : ''}
                    onChange={(event, newValue) => {
                      setFieldValue(`benchmark`, newValue ? newValue.symbol : '');
                    }}
                    renderInput={(params) => {
                      const fieldMeta = getFieldMeta('benchmark');
                      return (
                        <TextField {...params} label="Select Benchmark" error={!!fieldMeta.error} helperText={fieldMeta.error} />
                      )
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <Box sx={{ width: isMobile? '40%':'10%' }}>
                  <Button 
                    variant="contained" 
                    color="primary" 
                    type="submit" 
                    size='large' 
                    align='center'
                    onClick={() => ReactGA.event({
                      category: 'Button',
                      action: 'Clicked Compare Button',
                    })}
                  >
                    Compare
                  </Button>
                </Box>
                </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default CompareForm;
